import resume from '../assets/pdf/resume.pdf'
import cv_foto from '../assets/jpg/cv_foto.jpeg'

export const headerData = {
    name: 'Teun van Dalen',
    title: "Software engineer & Data scientist",
    desciption: '"Programming today is a race between software engineers striving to build bigger and better idiot-proof programs, and the Universe trying to produce bigger and better idiots. So far, the Universe is winning." — Rich Cook',
    image: cv_foto,
    resumePdf: resume
}
