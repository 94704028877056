export const experienceData = [
    {
        id: 1,
        company: 'Triodos Bank',
        jobtitle: 'Software developer & Riskshield specialist',
        startYear: '2024',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Trainee Software Engineering',
        jobtitle: 'Sogyo - Utrecht',
        startYear: '2023',
        endYear: '2024'
    },
    {
        id: 3,
        company: 'GGD Gelderland',
        jobtitle: 'Intervention specialist - Covid-19 Team',
        startYear: '2022',
        endYear: '2022'
    },
    {
        id: 4,
        company: 'Wageningen University',
        jobtitle: 'Student assistant - Programming in R / Python',
        startYear: '2021',
        endYear: '2022'
    }
]