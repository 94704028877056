export const educationData = [
    {
        id: 1,
        institution: 'Sogyo',
        course: 'Traineeship Software Engineering',
        startYear: '2023',
        endYear: '2024'
    },
    {
        id: 2,
        institution: 'Utrecht University',
        course: 'Master Applied Data Science',
        startYear: '2022',
        endYear: '2023'
    },
    {
        id: 3,
        institution: 'Wageningen University and Research',
        course: 'Bachelor Nutrition and Health',
        startYear: '2018',
        endYear: '2022'
    }
]