import camera from '../assets/png/camera.png'
import spotify from '../assets/png/spotify.png'

export const projectsData = [
    {
        id: 1,
        projectName: 'Caption This',
        projectDesc: 'A real-time multiplayer game where players caption eachothers photos.',
        tags: ['React', 'Socket.io', 'Typescript'],
        code: 'https://github.com/seroteunine/CaptionThis',
        demo: 'http://teunvandalen.nl/captionthis',
        image: camera
    },
    {
        id: 2,
        projectName: 'SpotiFast',
        projectDesc: 'An open-source tool for downloading Spotify playlist as 320kbp/s mp3 files.',
        tags: ['Python', 'Selenium', 'Spotify API'],
        code: 'https://github.com/seroteunine/Spotifast',
        demo: 'https://github.com/seroteunine/Spotifast',
        image: spotify
    }
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/